<template>
  <div class="container" ref="cate">
    <div
      class="sort"
      ref="sort"
      :style="showShadow ? 'box-shadow: 0 1px 8px -1px rgba(0,0,0,.2);' : ''"
    >
      <div class="first">
        <div class="first-sort">
          <div
            :class="activeSort == item.id ? 'sort-item active' : 'sort-item'"
            v-for="item in sortList"
            :key="item.id"
            @click="toCate(item.id)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="h"></div>
      <!-- 二级分类 -->
      <div class="second">
        <div class="second-sort">
          <div
            :class="activeSort2 == -1 ? 'clickSort sort-item ' : 'sort-item'"
            @click="clickAll"
          >
            {{ $t("cate.title") }}
          </div>
          <div
            :class="
              activeSort2 == item.id ? 'clickSort sort-item ' : 'sort-item'
            "
            v-for="(item, index) in secondSort"
            :key="index"
            @click="clickSort(item.id)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <!-- 商品列表 -->
    <div class="content" ref="scroll" v-if="showSort">
      <div class="sort-content" v-for="(item, index) in prods" :key="index">
        <div class="sort-title">{{ item.categoryName }}</div>
        <div class="sort-prods">
          <div
            class="prod"
            v-for="(item2,index2) in item.products"
            :key="index2"
            @click="
              $router.push({ name: 'prod', params: { id: item2.productId } })
            "
            data-aos="fade-up"
          >
            <a href="javascript:;">
              <div class="pic">
                <img :src="item2.imgPath" alt="" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索结果 -->
    <div class="sort-search-result" v-else>
      <div
        class="prod"
        v-for="item3 in searchResult"
        :key="item3.productId"
        @click="$router.push({ name: 'prod', params: { id: item3.productId } })"
        data-aos="fade-up"
      >
        <a href="javascript:;">
          <div class="pic">
            <img :src="item3.imgPath" alt="" />
          </div>
        </a>
      </div>
    </div>
    <backTop></backTop>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import { searchProd } from "../../network/search";
import { getCategory, getProds } from "../../network/category";
import BackTop from "../../components/content/backTop/BackTop.vue";
export default {
  name: "Category",
  data() {
    return {
      showSort: true, //是否显示子类别
      activeSort: -1, //一级分类 -下划线
      activeSort2: -1, //二级分类-下划线
      sortList: [], //分类
      secondSort: [], //当前二级分类
      prods: [],
      saveY: 0, //存储Y
      showShadow: false, //分类吸顶显示阴影
      searchResult: [], //搜索结果
    };
  },
  created() {
    if (!this.searchText) {
      this.getCategory();
    } else {
      this.showSort = false;
      this.searchProds();
    }
  },

  mounted() {
    var that = this;
    window.addEventListener("scroll", that.scroll);
  },

  beforeDestroy() {
    var that = this;
    window.removeEventListener("scroll", that.scroll); //监听页面滚动事件
  },

  watch: {
    // prods(val,oldVal){
    //   // console.log(oldVal);
    //   // console.log(val);
    //   setTimeout(()=>{
    //     if(val.length==0){
    //       this.getProds(this.activeSort, 0);
    //     }else{
    //       return false;
    //     }
    //   },10000)
    // }
  },
  updated() {
    this.$nextTick(() => {
      AOS.refresh();
    });
  },
  computed: {
    searchText() {
      return this.$route.query.res;
    },
  },
  methods: {

    scroll() {
      var that = this;
      var sortHeight =
        this.$refs.cate.getBoundingClientRect().top + window.scrollY;
      var scrollHeight =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      window.addEventListener("scroll", function () {
        if (scrollHeight > sortHeight) {
          that.showShadow = true;
        } else {
          that.showShadow = false;
        }
      });
    },
    //搜索
    searchProds() {
      searchProd(this.searchText).then((res) => {
        // console.log(res.data);
        res.data.forEach((item) => {
          this.searchResult = { ...this.searchResult, ...item.products };
        });
        // console.log(this.searchResult);
      });
    },
    //点击全部
    clickAll() {
      this.activeSort2 = -1;
      this.getProds(this.activeSort, 0);
    },
    //点击二级分类
    clickSort(id) {
      this.activeSort2 = id;
      this.getProds(this.activeSort, this.activeSort2);
    },
    //点击一级分类
    toCate(id) {
      this.activeSort = id;
      this.activeSort2 = -1; //二级分类变成全部
      this.getProds(id, 0);
      for (let i = 0; i < this.sortList.length; i++) {
        if (id == this.sortList[i].id) {
          this.secondSort = this.sortList[i].categories;
        }
      }
    },
    //获取所有分类
    getCategory() {
      getCategory().then((res) => {
        // console.log(res.data);
        if (res.status !== "ok") {
          return this.$message.error("获取产品信息失败");
        }
        this.sortList = res.data;
        this.secondSort = res.data[0].categories;
        if (!this.$route.params.cid || this.$route.params.cid == " ") {
          this.activeSort = res.data[0].id; //默认选中的一级分类
        } else {
          this.toCate(this.$route.params.cid);
        }
        this.getProds(this.activeSort, 0);
      });
    },
    //获取产品
    getProds(parentId, cateId) {
      getProds(parentId, cateId).then((res) => {
        if (res.status !== "ok") {
          return this.$message.error("获取产品信息失败");
        }
        // console.log(res.data);
        this.prods = res.data;
      });
    },
  },
  components: {
    BackTop,
  },
};
</script>

<style scoped lang="less">
@import "../../assets/css/category/Category.less";
</style>
