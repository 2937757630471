import {request} from './request'
//获取分类
export function getCategory(){
    return request({
        url:'/api/Category/GetCategory'
    })
}

//获取产品其他信息
export function getProdOtherInfo(){
    return request({
        url:'/api/Product/GetProductSettings'
    })
}
//获取产品
export function getProds(parentId,cateId){
    return request({
        url:`/api/Product/GetProductByCategory?categoryParentId=${parentId}&categoryId=${cateId}`
    })
}

//获取单品详情
export function getProdInfo(id){
    return request({
        url:`/api/Product/GetProductDetail?id=${id}`
    })
}